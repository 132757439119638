import React, { useEffect, useRef, memo } from 'react';
import '../../../index.css';
import { widget } from '../../../charting_library';
import Datafeed from './datafeed';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function getCurrentUnixTimestamp() {
    return Math.floor(Date.now() / 1000);  // Get current time in seconds since Unix epoch
}

function getLatestTime(data) {
    const times = [
        data.starttime,
        data.atime,
        data.btime,
        data.ctime,
        data.dtime,
        data.etime,
        data.endtime
    ].filter(time => time); // Filter out undefined/null values
    
    return Math.max(...times);
}

// const data = {
//     "points": [
//         {
// 			"xtime": 1717430400,
//             "xprice": 1.3624900000000002,
//             "atime": 1717434600,
//             "aprice": 1.3654700000000002,
//             "btime": 1717440000,
//             "bprice": 1.364215,
//             "ctime": 1717440900,
//             "cprice": 1.364865,
//             "dtime": 1717446600,
//             "dprice": 1.363095,
//             "endTime": "",
//             "endPrice": "",
//             "mature": "0",
//             "securityType": "forex",
//             "terminal": "0",
//             "patternname": "Bat",
//             "patterntype": "bullish",
//         },
// 		{
//             "aprice": 1.12749,
//             "atime": 1688158800,
//             "bprice": 1.04477,
//             "btime": 1696107600,
//             "cprice": 1.11388,
//             "ctime": 1701381600,
//             "dprice": 0.97335258,
//             "dtime": 1711918800,
//             "entry": 0.97335258,
//             "mature": 0,
//             "patternname": "Bat",
//             "patterntype": "bullish",
//             "profit1": 1.03223307,
//             "profit2": 1.06860951,
//             "przmax": 0.98003904,
//             "przmin": 0.97335258,
//             "rrratio": 3.34,
//             "sortTime": 1701381600,
//             "status": "incomplete",
//             "stoploss": 0.9448215,
//             "symbol": "OANDA:EUR_USD",
//             "terminal": 0,
//             "xprice": 0.95352,
//             "xtime": 1661979600

// 		}
//     ]
// };

const harmonic_patterns_names = {
    "gartley": "gartley",
    "butterfly": "butterfly",
    "crab": "crab",
    "deep crab": "deep_crab",
    "bat": "bat",
    "alternate bat": "alternate_bat",
    "shark": "shark",
    "cypher": "cypher",
}
   

export const TradingViewWidget = (props) => {
    console.log(props)
	const chartContainerRef = useRef();

    const actualTime = getCurrentUnixTimestamp();

	var intervalTime = "5";
	
	if (props.interval === "M5") {	
		intervalTime  = "5";
	}
	if (props.interval === "M15") {
		intervalTime = "15";
	}
	if (props.interval === "M30") {
		intervalTime = "30";
	}
	if (props.interval === "H1") {
		intervalTime = "60";
	}
	if (props.interval === "H4") {
		intervalTime  = "240";
	}
	if (props.interval === "D") {
		intervalTime  = "1D";
	}

	const defaultProps = {
		symbol: props.symbol ? props.symbol : 'OANDA:EUR_CAD',
		interval: props.interval ? intervalTime : '5',
		datafeedUrl: Datafeed,
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'public_user_id',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
	};

    let latestTime;
    if (props.data) {
	    latestTime = getLatestTime(props.data);
    }else{
        latestTime = actualTime;
    } 

	useEffect(() => {
        console.log(Datafeed)
		const widgetOptions = {
			symbol: defaultProps.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: Datafeed,
			interval: defaultProps.interval,
			container: chartContainerRef.current,
			library_path: defaultProps.libraryPath,

			locale: getLanguageFromURL() || 'en',
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: defaultProps.chartsStorageUrl,
			charts_storage_api_version: defaultProps.chartsStorageApiVersion,
			client_id: defaultProps.clientId,
			user_id: defaultProps.userId,
			fullscreen: defaultProps.fullscreen,
			autosize: defaultProps.autosize,
			studies_overrides: defaultProps.studiesOverrides,
            timezone: "America/New_York",
            theme: "Dark"

		};
        
		const tvWidget = new widget(widgetOptions);

        tvWidget.onChartReady(() => {
            console.log('Chart has loaded!');
            const chart = tvWidget.chart();
			tvWidget.chart();
            console.log("PROPS", props)
            console.log([
                { price: parseFloat(props.data.xprice), time: parseFloat(props.data.xtime) },
                { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                { price: parseFloat(props.data.dprice), time: parseFloat(props.data.dtime) }
                ])
            if (harmonic_patterns_names.hasOwnProperty(props.data.patternname)) {
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.data.xprice), time: parseFloat(props.data.xtime) },
                    { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                    { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                    { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                    { price: parseFloat(props.data.dprice), time: parseFloat(props.data.dtime) }
                    ],
                    { 
                        shape: 'xabcd_pattern',
                        overrides: {
                            linecolor: '#ff9933',  // Orange
                            linewidth: 2
                        }
                    }
                );
                }
            if (props.data.patternname === "double top" || props.data.patternname === "double bottom"){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                    { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                    { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                    ],
                    { shape: 'polyline' }
                );
            }
            if (props.data.patternname === "head and shoulders" || props.data.patternname === "inverse head and shoulders"){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.data.startprice), time: parseFloat(props.data.starttime) },
                    { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                    { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                    { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                    { price: parseFloat(props.data.dprice), time: parseFloat(props.data.dtime) },
                    { price: parseFloat(props.data.eprice), time: parseFloat(props.data.etime)},
                    { price: parseFloat(props.data.endprice), time: parseFloat(props.data.endtime)}
                    ],
                    { shape: 'head_and_shoulders' }
                );
            }
            if (props.data.patternname === "wedge" || props.data.patternname === "triangle"){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                    { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                    { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                    { price: parseFloat(props.data.dprice), time: parseFloat(props.data.dtime) },
                    ],
                    { shape: 'triangle_pattern' }
                );
            }
            if (props.data.patternname === "triple top" || props.data.patternname === "triple bottom"){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.data.startprice), time: parseFloat(props.data.starttime) },
                    { price: parseFloat(props.data.aprice), time: parseFloat(props.data.atime) },
                    { price: parseFloat(props.data.bprice), time: parseFloat(props.data.btime) },
                    { price: parseFloat(props.data.cprice), time: parseFloat(props.data.ctime) },
                    { price: parseFloat(props.data.dprice), time: parseFloat(props.data.dtime) },
                    { price: parseFloat(props.data.eprice), time: parseFloat(props.data.etime)},
                    { price: parseFloat(props.data.endprice), time: parseFloat(props.data.endtime)},
                    ],
                    { shape: 'polyline' }
                );
            }
            if (props.takeProfits.profit1 != null && props.takeProfits.profit1 > 0){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.takeProfits.profit1), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'horizontal_ray',
                        overrides: {
                            linecolor: '#00FF00',  // Green
                            linewidth: 2
                        }
                    }
                );
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.takeProfits.profit1), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'text', 
                        text: "Take Profit 1",
                        overrides: {
                            color: '#FFFFFF',  // White
                            fontsize: 12
                        }
                    }
                );
            }
            if (props.takeProfits.profit2 != null && props.takeProfits.profit2 > 0){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.takeProfits.profit2), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'horizontal_ray',
                        overrides: {
                            linecolor: '#00FF00',  // Green
                            linewidth: 2
                        }
                    }
                );
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.takeProfits.profit2), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'text', 
                        text: "Take Profit 2",
                        overrides: {
                            color: '#FFFFFF',  // White
                            fontsize: 12
                        }
                    }
                );
            }
            if (props.entryPrice != null && props.entryPrice > 0){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.entryPrice), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'horizontal_ray',
                        overrides: {
                            linecolor: '#0000FF',  // Blue
                            linewidth: 2
                        }
                    }
                );
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.entryPrice), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'text', 
                        text: "Entry Price",
                        overrides: {
                            color: '#FFFFFF',  // White
                            fontsize: 12
                        }
                    }
                );
            }
            if (props.stopLoss != null && props.stopLoss > 0){
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.stopLoss), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'horizontal_ray',
                        overrides: {
                            linecolor: '#FF0000',  // Red
                            linewidth: 2
                        }
                    }
                );
                chart.createMultipointShape(
                    [
                    { price: parseFloat(props.stopLoss), time: parseFloat(latestTime) },
                    ],
                    { 
                        shape: 'text', 
                        text: "Stop Loss",
                        overrides: {
                            color: '#FFFFFF',  // White
                            fontsize: 12
                        }
                    }
                );
            }
            });
            

		return () => {
			tvWidget.remove();
		};
	});

  return (
    <div className="tradingview-widget-container" ref={chartContainerRef} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "1080px" }}></div>
    </div>
  );
}

export default memo(TradingViewWidget);